import { PaginationProps } from 'antd';
import { createSlice, createAction, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, FbAdvertState, ShowFbRuleModal } from 'src/dashboard/types';
import { getFbDatawakeRuleDataHttp, getFbRuleDataHttp } from './api';
import { ShopAccountIdItem, VariableInfo } from './type';
import { setLocalShopAccountId } from 'src/utils/adHelper';
import { ConditionConfig } from '../Adtopic/component/RightContent/AutoRule/RuleDetail/Condition/type';

export const initPagination = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
};

const initialState: FbAdvertState = {
  leftMenuId: (location.pathname === '/fb/' ? new URLSearchParams(location.search).get('id') : 'rule') || 'rule',
  showFbRuleModal: {
    visible: false,
  },
  showFbFbRuleModal: {
    visible: false,
  },
  dataLoading: false,
  ruleDataSource: [],
  fbRuleDataSource: [],
  pagination: initPagination,
  shopAccountIdIOptions: [],
};

/** leftMenuId */
export const getLeftMenuId = (state: RootState) => state.fbAdvert.leftMenuId || 'rule';
/** 获取是否显示规则弹窗 */
export const getShowFbRuleModal = (state: RootState) => state.fbAdvert.showFbRuleModal;
/** 获取是否显示规则弹窗 */
export const getShowFbFbRuleModal = (state: RootState) => state.fbAdvert.showFbFbRuleModal;
/** 获取数据加载状态 */
export const getFbDataLoading = (state: RootState) => state.fbAdvert.dataLoading;
/** 获取规则数据 */
export const getFbRuleDataSource = (state: RootState) => state.fbAdvert.ruleDataSource || [];
/** 获取规则引擎数据 */
export const getFbFbRuleDataSource = (state: RootState) => state.fbAdvert.fbRuleDataSource || [];
/** 获取分页数据 */
export const getFbPagination = (state: RootState) => state.fbAdvert.pagination;
/** 获取selectShopAccountId */
export const getSelectShopAccountId = (state: RootState) => state.fbAdvert.selectShopAccountId;
export const getShopAccountIdIOptions = (state: RootState) => state.fbAdvert.shopAccountIdIOptions;
/** 获取条件配置conditionConfig */
export const getConditionConfig = (state: RootState) => state.fbAdvert.conditionConfig;
/** 获取dsl变量信息 */
export const getDslVariablesInfo = (state: RootState) => state.fbAdvert.dslVariablesInfo;
/** 获取dsl变量初始值 */
export const getInitDslVariables = (state: RootState) => state.fbAdvert.initDslVariables || {};


export const getDatawakeFbRuleData = createAsyncThunk('fbAdvert/getDatawakeFbRuleData', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const pagination = getFbPagination(state);
  const res = await getFbDatawakeRuleDataHttp({
    page: pagination.current || 1,
    page_size: pagination.pageSize || 10
  });

  const newPagination = {
    current: res.pagination?.page || 1,
    pageSize: res.pagination?.page_size || 10,
    total: res.pagination?.element_total || 0,
    showSizeChanger: true,
  };
  if (newPagination.current > res.pagination?.page_total && res.pagination?.page_total > 0) {
    newPagination.current = res.pagination?.page_total || 1;
    dispatch(updateFbPagination(newPagination));
    dispatch(getDatawakeFbRuleData());
    return;
  }
  dispatch(updateFbPagination(newPagination));
  return res.ret || [];
});

export const getFbRuleData = createAsyncThunk('fbAdvert/getRuleData', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const pagination = getFbPagination(state);
  const res = await getFbRuleDataHttp({
    page: pagination.current || 1,
    page_size: pagination.pageSize || 10
  });

  const newPagination = {
    current: res.pagination?.page || 1,
    pageSize: res.pagination?.page_size || 10,
    total: res.pagination?.element_total || 0,
    showSizeChanger: true,
  };
  if (newPagination.current > res.pagination?.page_total && res.pagination?.page_total > 0) {
    newPagination.current = res.pagination?.page_total || 1;
    dispatch(updateFbPagination(newPagination));
    dispatch(getFbRuleData());
    return;
  }
  dispatch(updateFbPagination(newPagination));
  return res.ret || [];
});


export const fbAdvertSlice = createSlice({
  name: 'fbAdvert',
  initialState,
  reducers: {
    updateLeftMenuId: (state, action: PayloadAction<string>) => {
      state.leftMenuId = action.payload;
    },
    updateShowFbRuleModal: (state, action: PayloadAction<ShowFbRuleModal | undefined>) => {
      state.showFbRuleModal = action.payload;
    },
    updateShowFbFbRuleModal: (state, action: PayloadAction<ShowFbRuleModal | undefined>) => {
      state.showFbFbRuleModal = action.payload;
    },
    updateFbDataLoading: (state, action: PayloadAction<boolean>) => {
      state.dataLoading = action.payload;
    },
    updateFbPagination: (state, action: PayloadAction<PaginationProps>) => {
      state.pagination = action.payload;
    },
    updateSelectShopAccountId: (state, action: PayloadAction<string>) => {
      setLocalShopAccountId(action.payload);
      state.selectShopAccountId = action.payload;
    },
    updateShopAccountIdIOptions: (state, action: PayloadAction<ShopAccountIdItem[]>) => {
      state.shopAccountIdIOptions = action.payload;
    },
    updateConditionConfig: (state, action: PayloadAction<ConditionConfig | undefined>) => {
      state.conditionConfig = action.payload;
    },
    updateDslVariablesInfo: (state, action: PayloadAction<Record<string, VariableInfo> | undefined>) => {
      state.dslVariablesInfo = action.payload;
    },
    updateInitDslVariables: (state, action: PayloadAction<Record<string, any> | undefined>) => {
      state.initDslVariables = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(resetModalAction, () => initialState)
    .addCase(getFbRuleData.pending, (state) => {
      state.dataLoading = true;
    })
    .addCase(getFbRuleData.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload) {
        return;
      }
      state.fbRuleDataSource = action.payload;
    })
    .addCase(getFbRuleData.rejected, (state) => {
      state.dataLoading = false;
    })
    .addCase(getDatawakeFbRuleData.pending, (state) => {
      state.dataLoading = true;
    })
    .addCase(getDatawakeFbRuleData.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload) {
        return;
      }
      state.ruleDataSource = action.payload;
    })
    .addCase(getDatawakeFbRuleData.rejected, (state) => {
      state.dataLoading = false;
    })
  }
})

const resetModalAction = createAction('fbAdvert/reset-action');

export const {
  updateLeftMenuId,
  updateShowFbRuleModal,
  updateShowFbFbRuleModal,
  updateFbDataLoading,
  updateFbPagination,
  updateSelectShopAccountId,
  updateShopAccountIdIOptions,
  updateConditionConfig,
  updateDslVariablesInfo,
  updateInitDslVariables,
} = fbAdvertSlice.actions;

export { resetModalAction };

export default fbAdvertSlice.reducer;
