import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './index.less';
import SellerModal from 'src/pages/Authorization/components/SellerModal';
import { SupersetClient } from '@superset-ui/core';
import { isDemoAccount } from 'src/utils/demoHelper';
import { isAdminGroup } from 'src/features/home/userGroup';
import { updateIsNoAuth } from 'src/pages/AiHelper/slice';
import { getMySubscript } from 'src/pages/User/components/OrderList/api';
import BuyModal from '../BuyModal';
import dayjs from 'dayjs';

const FREE = 'FREE';

export default function (props: any) {
  const { path = '' } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isShowBuyModal, setIsShowBuyModal] = useState<boolean>(false);
  const [isExpiredAt, setIsExpiredAt] = useState<string>(''); // 过期时间
  const [authFlag, setAuthFlag] = useState<boolean>(false);
  const [maskAuthFlag, maskSetAuthFlag] = useState<boolean>(false);
  const reactLocation = useLocation();

  const checkFree = () => {
    getMySubscript().then((res) => {
      const ret = res.json?.ret;
      if (ret?.subscription_level === FREE) {
        // 免费版账号暂无权限使用，需打开付费弹窗
        setIsShowBuyModal(true);
      }
      if (ret?.expired && ret?.expired_at && ret?.subscription_level !== FREE) {
        setIsShowBuyModal(true);
        setIsExpiredAt(dayjs(ret?.expired_at).format('YYYY-MM-DD HH:mm:ss'));
      }
    });
  };

  useEffect(() => {
    // const isTempBlockAuth = window.sessionStorage.getItem('__maskAuthFlag__');
    // if(isTempBlockAuth) return;
    if (['/home/', '/register/', '/login/', '/pricing/', '/privacy/'].includes(window.location.pathname)) {
      // 首页、注册、登录页、隐私政策页4不需要检测授权 - 临时处理
      return;
    }
    SupersetClient.get({
      host: `${window.location.host}/extra_server/`,
      endpoint: `user/dataAuth`,
      mode: 'cors',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: null,
      parseMethod: 'json',
    }).then(data => {
      const { json = {} } = data;
      setTimeout(() => {
        if (json.ret === "NoAuth" && !isAdminGroup()) {
          dispatch(updateIsNoAuth(true));
          maskSetAuthFlag(true);
          if (isDemoAccount()) {
            // 体验账号也需要检测付费
            checkFree();
          }
        } else {
          checkFree();
        }
      }, 800);

    });
  }, []);

  const tryDemo = () => {
    window.localStorage.setItem('__isDemo_account__', 'true'); // 设置账户类型
    maskSetAuthFlag(false);
    location.reload();
  }

  const routeMap: Record<string, string> = {
    '/chat/': 'AI 问答',
    '/dashboard/list/': '看板',
    '/chart/list/': '图卡',
    '/util/monitor': '关键词监控',
  }

  if (isShowBuyModal && (path === '/adtopic' || path === '/reports')) {
    // 目前只对 adtopic 和 reports 页面打开付费弹窗
    return (
      <BuyModal isExpiredAt={isExpiredAt}>
        {props.children}
      </BuyModal>
    );
  }

  if (isDemoAccount() || (!maskAuthFlag) || path === '/user/info/' || path === '/auth/' || path === '/pricing/' || path === '/fb/auth/' || path === '/fb/' || path === '/fb/home/' || path === '/fb/rule/') {
    return props.children;
  }
  return (
    <div className="mask-content">
      <div className="mask-warpper" />
      <div className="mask-container">
        <p className="title">{routeMap[reactLocation.pathname] || ''}</p>
        <p className="content">
          您的账户已经完成“店铺授权”，但仍未完成“广告授权”，暂无法查看数据。请前往“广告授权”。
        </p>
        <div className="go-auth" onClick={() => { 
          // setAuthFlag(true)
          history.push(`/auth/`);
        }}>前往广告授权</div>
        <div className="go-demo" onClick={tryDemo}>体验DEMO</div>
      </div>
      {authFlag ? (
        <SellerModal visible={authFlag} cancelShow={() => { setAuthFlag(false) }} />
      ) : (
        <div />
      )}
      {props.children}
    </div>
  );
}
