import { allHttpRequest } from 'src/utils/httpRequest';
import { CreateOrderParams } from './type';

// 创建订单
export const createOrder = async (filter: CreateOrderParams) => {
  const res = await allHttpRequest.post('/llm_server/pay/create_order', {
    "order_params": filter
  });
  return res;
};

// 获取订单状态
export const getOrderStatus = async (order_id: string) => {
  const res = await allHttpRequest.get(`/llm_server/pay/query_order_status/${order_id}`);
  return res;
};
