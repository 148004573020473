const LOCAL_SHOP_ACCOUNT_NAME = '__shop_account_name__';

const SHOP_ACCOUNT_NAME_KEY = 'shop-name';

const getLocalShopAccountName = () => {
  return window.localStorage.getItem(LOCAL_SHOP_ACCOUNT_NAME);
};

const setLocalShopAccountName = (shopAccountName: string) => {
  window.localStorage.setItem(LOCAL_SHOP_ACCOUNT_NAME, shopAccountName);
};

const clearLocalShopAccountName = () => {
  window.localStorage.removeItem(LOCAL_SHOP_ACCOUNT_NAME);
};

const COUNTRY_NAME_KEY = 'country-name';

const SHOP_ACCOUNT_ID = '__shop_account_id__';

const getLocalShopAccountId = () => {
  return window.localStorage.getItem(SHOP_ACCOUNT_ID);
};

const setLocalShopAccountId = (shopAccountId: string) => {
  window.localStorage.setItem(SHOP_ACCOUNT_ID, shopAccountId);
};

/** 百分比字段 */
const percentKeys = ['acos_14d_min', 'acos_14d_max', 'ctr_min', 'ctr_max', 'cvr_14d_min', 'cvr_14d_max'];

const removeNullProperties = (obj: Record<string, any>): Record<string, any> => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([_, value]) => value !== null);
  return Object.fromEntries(filteredEntries);
};

/**
 * 精确调整数字并乘以指定乘数
 * 
 * 这个函数首先将输入数字调整为整数以避免浮点数精度问题,
 * 然后乘以指定的乘数,最后再将结果调整回原来的小数位数。
 * 
 * @param num 要调整的数字
 * @param multiplier 要乘的乘数
 * @returns 精确调整和相乘后的结果
 */
const adjustForPrecision = (num: number, multiplier: number) => {
  // 将数字转换为字符串
  const numStr = num.toString();

  // 如果数字没有小数点，直接相乘
  if (!numStr.includes('.')) {
    return num * multiplier;
  }

  // 计算小数位数
  const decimalPlaces = numStr.split('.')[1].length;
  
  // 计算乘数
  const factor = Math.pow(10, decimalPlaces);
  
  // 调整数字为整数
  const adjustedNum = num * factor;
  
  // 进行计算，使用传入的multiplier参数
  const result = adjustedNum * multiplier;

  // 将结果转换回原来的小数位
  return result / factor;
};

/**
 * 将百分比字段转换为百分比数字
 * @param obj 
 * @returns 
 */
const convertPercentKeys = (obj: Record<string, any>): Record<string, any> => {
  const newObj = { ...obj };
  percentKeys.forEach(key => {
    if (!newObj[key]) {
      return;
    }
    newObj[key] = adjustForPrecision(newObj[key], 100);
  });
  return newObj;
};

export const isHostFb = window.location.host.split('.').includes('fb');

export {
  getLocalShopAccountName,
  setLocalShopAccountName,
  SHOP_ACCOUNT_NAME_KEY,
  COUNTRY_NAME_KEY,
  clearLocalShopAccountName,
  removeNullProperties,
  convertPercentKeys,
  getLocalShopAccountId,
  setLocalShopAccountId,
};
