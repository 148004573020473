/* eslint-disable theme-colors/no-literal-colors */
/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dropdown } from "antd";
import { styled, css, useTheme, SupersetTheme } from '@superset-ui/core';
import { debounce } from 'lodash';
import { Global } from '@emotion/react';
import { getUrlParam } from 'src/utils/urlUtils';
import { Row, Col, Grid } from 'src/components';
import { MainNav as DropdownMenu, MenuMode } from 'src/components/Menu';
import { Tooltip } from 'src/components/Tooltip';
import { useHistory, NavLink, useLocation } from 'react-router-dom';

import Icons from 'src/components/Icons';
import { useUiConfig } from 'src/components/UiConfigContext';
import { URL_PARAMS } from 'src/constants';
import {
  MenuObjectChildProps,
  MenuObjectProps,
  MenuData,
} from 'src/types/bootstrapTypes';
import { useDispatch } from 'react-redux';
import { isHostFb } from 'src/utils/adHelper';
import { isDemoAccount } from 'src/utils/demoHelper';
import { updateAssistantId } from 'src/pages/AiAnalyse/slice';
import { mainAssistantId } from 'src/pages/AiAnalyse/utils/gptsConfig';
import Adtopic from 'src/assets/images/home/adtopic.png';
import Business from 'src/assets/images/home/business.png';
import Laboratory from 'src/assets/images/home/laboratory.png';
import Chevrondown from 'src/assets/images/home/chevrondown.png';
import RightMenu from './RightMenu';

interface MenuProps {
  data: MenuData;
  isFrontendRoute?: (path?: string) => boolean;
}

const StyledHeader = styled.header`
  ${({ theme }) => `
      background-color: ${theme.colors.grayscale.light5};
      z-index: 102;

      &:nth-last-of-type(2) nav {
        margin-bottom: 2px;
      }
      .caret {
        display: none;
      }
      .brandTip{
        height: 30px;
        background-color: #DCE8FF;
        width: 100%;
        line-height: 30px;
        text-align: center;
        box-sizing: border-box;
      }
      .navbar-brand {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* must be exactly the height of the Antd navbar */
        height: 60px;
        margin-right: 10px;
        padding: 15px 30px;
        max-width: ${theme.gridUnit * theme.brandIconMaxWidth}px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .navbar-brand-text {
        border-left: 1px solid ${theme.colors.grayscale.light2};
        border-right: 1px solid ${theme.colors.grayscale.light2};
        height: 100%;
        color: ${theme.colors.grayscale.dark1};
        padding-left: ${theme.gridUnit * 4}px;
        padding-right: ${theme.gridUnit * 4}px;
        margin-right: ${theme.gridUnit * 6}px;
        font-size: ${theme.gridUnit * 4}px;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          max-width: ${theme.gridUnit * 58}px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media (max-width: 1127px) {
          display: none;
        }
      }
      .main-nav .ant-menu-submenu-title > svg {
        top: ${theme.gridUnit * 5.25}px;
      }
      .main-nav .ant-menu-item{
        margin-right:30px!important;
        width:80px;
        height:60px!important;
        line-height:60px!important;
        text-align:center;
      }
      .main-nav .ant-menu-item>a{
        font-size:16px!important;
        padding:4px!important;
      }
      @media (max-width: 767px) {
        .navbar-brand {
          float: none;
        }
      }
      .ant-menu-horizontal .ant-menu-item {
        height: 100%;
        line-height: inherit;
      }
      .ant-menu > .ant-menu-item > a {
        padding: ${theme.gridUnit * 4}px;
      }
      .ant-menu > .ant-menu-item-selected a{
        font-weight: 600;
        color:#005AFF;
        // border-bottom: 4px solid #005AFF!important;
      }
      .ant-menu > .ant-menu-item-selected{
        border:none;
        // border-bottom: 4px solid #005AFF;
      }
      .ant-menu > .ant-menu-item :after {
        top: 43.5px;
        background: transparent;
      }
      .ant-menu > .ant-menu-item-selected :after{
        content: ' ';
        display: inline-block;
        width: 42px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        top: 43.5px;
        bottom: 0;
        left: 50%;
        right: 0;
        opacity: 1;
        background-color: #005AFF;
        // border-bottom: 4px solid #005AFF;
        transform: translateX(-50%);
        transition: all 0.3s;
      }
     
      .ant-menu > .ant-menu-item a:hover {
        color: #005AFF;
        // background-color:rgba(0,90,225,0.1);
        // border-bottom: none;
        // margin: 0;
      }
     
      .ant-menu-item:active, .ant-menu-submenu-title:active{
        background-color:#fff;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background-color:#fff;
        border:none;
      }
      .ant-menu-inline .ant-menu-item:after{
        border-right:3px solid #005AFF;
      }
      .ant-menu-inline .ant-menu-item-active>a:hover{
        // color:#005AFF!important;
      }
      @media (max-width: 767px) {
        .ant-menu-item {
          padding: 0 ${theme.gridUnit * 6}px 0
            ${theme.gridUnit * 3}px !important;
        }
        .ant-menu > .ant-menu-item > a {
          padding: 0px;
        }
        .main-nav .ant-menu-submenu-title > svg:nth-of-type(1) {
          display: none;
        }
        // .ant-menu-item-active > a {
        //   &:hover {
        //     color: ${theme.colors.primary.base} !important;
        //     background-color: transparent !important;
        //   }
        // }
      }
      // .ant-menu-item a {
      //   &:hover {
      //     color: ${theme.colors.grayscale.dark1};
      //     background-color: ${theme.colors.primary.light5};
      //     border-bottom: none;
      //     margin: 0;
      //     &:after {
      //       opacity: 1;
      //       width: 100%;
      //     }
      //   }
      // }
  `}
`;
const StyledTopNav = styled.div`
  ${({ theme }) => `
      .topNav{
        height:60px;
        display:flex;
        a{
          text-decoration:none;
        }
        .navItem{
          width: 110px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "PingFang SC", "Segoe UI", "Helvetica Neue", "Arial", "Microsoft YaHei", serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          border-radius: 5px 5px 0 0;
          cursor: pointer;
          color: #000;
          position: relative;
          img{
            width:16px;
            height:16px;
            margin-left:8px;
            position: absolute;
            top:24px;
          }
          a{
            color: inherit!important;
          }
        }
        .navItem:hover{
          color:rgb(0, 90, 255);
          a{
            color: inherit!important;
          }
        }
        .navItem_active{
          font-weight: 600;
          color:rgb(0, 90, 255);
          a{
            color: inherit!important;
          }
        }
        .navItem::after{
          content: ' ';
          display: inline-block;
          top: 43.5px;
          opacity: 0;
          width: 0;
          height: 4px;
          border-radius: 4px;
          position: absolute;
        }
        .navItem_active::after{
          content: ' ';
          display: inline-block;
          width: 42px;
          height: 4px;
          border-radius: 4px;
          position: absolute;
          top: 43.5px;
          bottom: 0;
          left: 50%;
          right: 0;
          opacity: 1;
          background-color: #005AFF;
          transform: translateX(-50%);
          transition: all 0.3s;
        }
      }
  `}
`;
const StyledDataReport = styled.div`
  ${({ theme }) => `
  .reportPop{
    background-color:#fff;
    display: flex;
    justify-content: space-between;
    padding:0 20px 20px;
    box-sizing: border-box;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 5px 23.100000381469727px 0px #0000000D;
    .reportItem{
      width:160px;
      .reportHeader{
        width: 100%;
        color:#afafaf;
        text-align:left;
        color: #757F8D;
        font-size:14px;
        font-weight: 400;
        display:flex;
        align-items: center;
      }
      .reportContent{
        font-size: 14px;
        margin-top: 20px;
        text-align: left;
        padding-left: 19px;
        height: 20px;
        display:flex;
        align-items: center;
        font-weight: 400;
        .reportHeaderDot{
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 1px;
          transform: rotate(45deg);
          background-color: #005AFF;
          margin-right: 14px;
        }
      }
      .reportContent_active{
        color: #005AFF;
        a{
          color:inherit;
        }
      }
      .reportContent:hover{
        color: #005AFF;
      }
    }
  }
  `}`;
const StyledReport = styled.div`
  ${({ theme }) => `
  .reportPop{
    background-color:#fff;
    display: flex;
    justify-content: space-between;
    padding:20px;
    box-sizing: border-box;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 5px 23.100000381469727px 0px #0000000D;
    .reportItem{
      width:160px;
      .reportHeader{
        width: 100%;
        color:#000;
        text-align:left;
        font-size:16px;
        font-weight: 600;
        display:flex;
        align-items: center;
        img{
          width: 26px;
          height: 26px;
          display: block;
          margin-right:15px;
          position:initial;
        }
      }
      .reportContent{
        font-size: 14px;
        margin-top: 20px;
        text-align: left;
        height: 20px;
        font-weight: 400;
        display:flex;
        align-items: center;
        padding-left:18px;
        .reportHeaderDot{
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 1px;
          transform: rotate(45deg);
          background-color: #005AFF;
          margin-right: 24px;
        }
      }
      .reportContent_active{
        color: #005AFF;
        a{
          color:inherit;
        }
      }
      .reportContent:hover{
        color: #005AFF;
      }
    }
  }
  `}`;
const globalStyles = (theme: SupersetTheme) => css`
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-bottomLeft {
    border-radius: 0px;
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light {
    border-radius: 0px;
  }
  .ant-menu-vertical > .ant-menu-submenu.data-menu > .ant-menu-submenu-title {
    height: 28px;
    i {
      padding-right: ${theme.gridUnit * 2}px;
      margin-left: ${theme.gridUnit * 1.75}px;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent;
    &:not(.ant-menu-item-active) {
      color: inherit;
      border-bottom-color: transparent;
      & > a {
        color: inherit;
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-item:has(> .is-active) {
    color: ${theme.colors.primary.base};
    border-bottom-color: ${theme.colors.primary.base};
    & > a {
      color: ${theme.colors.primary.base};
    }
  }
  .ant-menu-vertical > .ant-menu-item:has(> .is-active) {
    background-color: ${theme.colors.primary.light5};
    & > a {
      color: ${theme.colors.primary.base};
    }
  }
`;
const { SubMenu } = DropdownMenu;

const { useBreakpoint } = Grid;

export function Menu({
  data: {
    menu,
    brand,
    navbar_right: navbarRight,
    settings,
    environment_tag: environmentTag,
  },
  isFrontendRoute = () => false,
}: MenuProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showMenu, setMenu] = useState<MenuMode>('horizontal');
  // const [selectItem, setSelectItem] = useState<string>('')
  const selectItem = useRef<string>('')
  const screens = useBreakpoint();
  const uiConfig = useUiConfig();
  const theme = useTheme();
  const [isDemo, setIsDemo] = useState<boolean>(false);
  useEffect(() => {
    setIsDemo(isDemoAccount());
    function handleResize() {
      if (window.innerWidth <= 767) {
        setMenu('inline');
      } else setMenu('horizontal');
    }
    handleResize();
    const windowResize = debounce(() => handleResize(), 10);
    window.addEventListener('resize', windowResize);

    // // 首次加载/刷新后
    // const currentPath = window.location.pathname
    // if (currentPath.startsWith('/superset/dashboard/')) {
    //   setSelectItem('found')
    // } else if (currentPath.startsWith('/chat/')) {
    //   setSelectItem('ai')
    // } else if (currentPath.startsWith('/explore/')) {
    //   setSelectItem('chat')
    // } else if (currentPath.startsWith('/chart/list/')||currentPath.startsWith('/dashboard/list/')) {
    //   setSelectItem('found')
    //  }
    // // 监听路由变化
    // const urlListen = history.listen((location) => {
    //   const currentRoute = menu.find(i => i.url?.startsWith(location.pathname))
    //   if (currentRoute) {
    //     if (currentRoute.key === "chat") {
    //       setSelectItem('found')
    //     } else if (currentRoute.key === "dashboard") {
    //       setSelectItem('found')
    //     } else if (currentRoute.key === "home") {
    //       setSelectItem('ai')
    //     }
    //   }
    // })
    // 首次加载/刷新后
    const currentPath = window.location.pathname
    if (currentPath.startsWith('/superset/dashboard/' || currentPath.startsWith('/dashboard/list/'))) {
      selectItem.current = 'topDashboard'
      if (currentPath.startsWith('/superset/dashboard/4822')) {
        selectItem.current = 'home'
      }
    } else if (currentPath.startsWith('/adtopic')) {
      selectItem.current = 'adtopic'
    } else if (currentPath.startsWith('/customAnalysisGuide')) {
      selectItem.current = 'customAnalysisGuide'
    } else if (currentPath.startsWith('/chat/')) {
      const assid = window.location.search ? decodeURI(window.location.search.split('=')[1]) : mainAssistantId
      selectItem.current = assid
      dispatch(updateAssistantId(assid));
    } else if (currentPath.startsWith('/explore/') || currentPath.startsWith('/chart/list/') || currentPath.startsWith('/chart/add')) {
      selectItem.current = 'chart'
    } else if (currentPath.startsWith('/tablemodelview/list/')) {
      selectItem.current = 'data'
    } else if (currentPath.startsWith('/util/monitor/')) {
      selectItem.current = 'monitor'
    } else if (currentPath.startsWith('/inspection')) {
      selectItem.current = 'inspection'
    } else if (currentPath.startsWith('/reports')) {
      selectItem.current = 'reports'
    } else if (currentPath.startsWith('/adtopic')) {
      selectItem.current = 'adtopic'
    } else if (currentPath === '/fb/') {
      selectItem.current = 'fb'
    } else if (currentPath === '/fb/auth/') {
      selectItem.current = 'fb_auth'
    } else if (currentPath === '/fb/home/') {
      selectItem.current = 'fb_home'
    } else if (currentPath === '/fb/rule/') {
      selectItem.current = 'fb_rule'
    }
    // 监听路由变化
    const urlListen = history.listen((location) => {
      const currentRoute = menu.find(i => i.url?.startsWith(location.pathname))

      if (currentRoute) {
        if (currentRoute.key === "chat") {
          selectItem.current = 'chart'
        } else if (currentRoute.key === "dashboard") {
          selectItem.current = 'topDashboard'
        } else if (currentRoute.key === "home") {
          const assid = location.search ? decodeURI(location.search.split('=')[1]) : mainAssistantId
          selectItem.current = assid
          dispatch(updateAssistantId(assid));
        }
      } else if (location.pathname.startsWith('/adtopic')) {
        selectItem.current = 'adtopic'
      } else if (location.pathname.startsWith('/customAnalysisGuide')) {
        selectItem.current = 'customAnalysisGuide'
      } else if (location.pathname.startsWith('/superset/dashboard/')) {
        selectItem.current = 'topDashboard'
        if (location.pathname.startsWith('/superset/dashboard/4822')) {
          selectItem.current = 'home'
        }
      } else if (location.pathname.startsWith('/explore/') || location.pathname.startsWith('/chart/list/') || location.pathname === '/chart/add') {
        selectItem.current = 'chart'
      } else if (location.pathname.startsWith('/tablemodelview/list/')) {
        selectItem.current = 'data'
      } else if (location.pathname.startsWith('/util/monitor/')) {
        selectItem.current = 'monitor'
      } else if (location.pathname === '/fb/') {
        selectItem.current = 'fb'
      } else if (location.pathname === '/fb/auth/') {
        selectItem.current = 'fb_auth'
      } else if (location.pathname === '/fb/home/') {
        selectItem.current = 'fb_home'
      } else if (location.pathname === '/fb/rule/') {
        selectItem.current = 'fb_rule'
      } else {
        selectItem.current = ''
      }
    })
    return () => {
      window.removeEventListener('resize', windowResize);
      urlListen(); // 卸载
    }
  }, []);

  enum paths {
    EXPLORE = '/explore',
    DASHBOARD = '/dashboard',
    CHART = '/chart',
    DATASETS = '/tablemodelview',
  }

  const defaultTabSelection: string[] = [];
  const [activeTabs, setActiveTabs] = useState(defaultTabSelection);
  const [openToolStatus, setOpenToolStatus] = useState(false)
  const handleToolOpenChange = (status: boolean) => {
    setOpenToolStatus(status);
  }
  const getPopupToolContainer = (HTMLElement: HTMLElement) => HTMLElement;
  const dropdownToolRender = useCallback(() =>
    <StyledReport>
      <div className='reportPop'>
        <div className='reportItem'>
          <div className='reportHeader'>
            <img src={Adtopic} alt="" />
            广告
          </div>
          {[
            { label: '广告优化', url: '/adtopic', key: 'adtopic' }
          ].map(item => (
            <div className={selectItem.current === item.key ? 'reportContent reportContent_active' : 'reportContent'} key={item.label} onClick={(e) => {
              // history.push(item.url)
              toNavPop(e, item.key)
            }}>
              <div className='reportHeaderDot' />
              <a href={item.url}>{item.label}</a>
            </div>
          ))}
        </div>
        <div className='reportItem'>
          <div className='reportHeader'>
            <img src={Business} alt="" />
            经营分析
          </div>
          {[
            // { label: '自助BI', url: '/chart/list/?filters=(id:(label:预置图卡,value:true))&favr=1',key:'chart' },
            // { label: '自定义分析', url: '/chart/list/?filters=(id:(label:预置图卡,value:true))&favr=1',key:'chart' },
            { label: '自定义分析', url: '/customAnalysisGuide', key: 'customAnalysisGuide' },
            { label: '图卡列表', url: '/chart/list/?filters=(id:(label:预置图卡,value:true))&favr=1', key: 'chart' },
            { label: '分析模板库', url: '/dashboard/list/?filters=(id:(label:预置看板,value:pre))', key: 'topDashboard' },
            { label: '数据集管理', url: '/tablemodelview/list/', key: 'data' },
          ].map(item => (
            <div className={selectItem.current === item.key ? 'reportContent reportContent_active' : 'reportContent'} key={item.label} onClick={(e) => {
              // history.push(item.url)
              toNavPop(e, item.key)
            }}>
              <div className='reportHeaderDot' />
              <a href={item.url}>{item.label}</a>
            </div>
          ))}
        </div>
        <div className='reportItem'>
          <div className='reportHeader'>
            <img src={Laboratory} alt="" />
            实验室
          </div>
          {[
            // { label: 'FB广告', url: '/fb/', key: 'fb' },
            { label: 'ABA选品', url: '/reports', key: 'reports' },
            { label: '智能巡检', url: '/inspection', key: 'inspection' },
            { label: '关键词监控', url: '/util/monitor/', key: 'monitor' },
            // { label: 'Excel插件', url: '/util/excel/' }
          ].map(item => (
            <div className={selectItem.current === item.key ? 'reportContent reportContent_active' : 'reportContent'} key={item.label} onClick={(e) => {
              // history.push(item.url)
              toNavPop(e, item.key)
            }}>
              <div className='reportHeaderDot' />
              <a href={item.url}>{item.label}</a>
            </div>
          ))}
        </div>
      </div>
    </StyledReport>
    ,
    [])
  const [openDataStatus, setOpenDataStatus] = useState(false)
  const handleDataOpenChange = (status: boolean) => {
    setOpenDataStatus(status);
  }
  const getPopupDataContainer = (HTMLElement: HTMLElement) => HTMLElement;
  const dropdownDataRender = useCallback(() =>
    <StyledDataReport>
      <div className='reportPop'>
        <div className='reportItem'>
          {/* <div className='reportHeader'><div className='reportHeaderDot' />实验室</div> */}
          {[
            { label: '自定义分析', url: '/customAnalysisGuide', key: 'customAnalysisGuide' },
            // { label: '自定义分析', url: '/chart/list/?filters=(id:(label:预置图卡,value:true))&favr=1',key:'chart' },
            { label: '图卡列表', url: '/chart/list/?filters=(id:(label:预置图卡,value:true))&favr=1', key: 'chart' },
            { label: '分析模板库', url: '/dashboard/list/?filters=(id:(label:预置看板,value:pre))', key: 'topDashboard' },
            { label: '数据集管理', url: '/tablemodelview/list/', key: 'data' }
          ].map(item => (
            <div className={selectItem.current === item.key ? 'reportContent reportContent_active' : 'reportContent'} key={item.label} onClick={(e) => {
              // history.push(item.url)
              toNavPop(e, item.key)
            }}>
              <div className='reportHeaderDot' />
              <a href={item.url}>{item.label}</a>
            </div>
          ))}
        </div>
      </div>
    </StyledDataReport>
    ,
    [])

  const [openAiStatus, setOpenAiStatus] = useState(false)
  const handleAiOpenChange = (status: boolean) => {
    setOpenAiStatus(status);
  }
  const getPopupAiContainer = (HTMLElement: HTMLElement) => HTMLElement;
  const dropdownAiRender = useCallback(() =>
    <StyledDataReport>
      <div className='reportPop'>
        <div className='reportItem'>
          {/* <div className='reportHeader'><div className='reportHeaderDot' />实验室</div> */}
          {[
            { label: '自助取数', url: '/chat/?id=AI分析教练', key: 'AI分析教练' },
            { label: 'AI小应用', url: '/chat/?id=smallApp', key: 'smallApp' }
          ].map(item => (
            <div className={selectItem.current === item.key ? 'reportContent reportContent_active' : 'reportContent'} key={item.label} onClick={(e) => {
              // history.push(item.url)
              toNavPop(e, item.key)
            }}>
              <div className='reportHeaderDot' />
              <a href={item.url}>{item.label}</a>
            </div>
          ))}
        </div>
      </div>
    </StyledDataReport>
    ,
    [])

  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    switch (true) {
      case path.startsWith(paths.DASHBOARD):
        setActiveTabs(['Dashboards']);
        break;
      case path.startsWith(paths.CHART) || path.startsWith(paths.EXPLORE):
        setActiveTabs(['Charts']);
        break;
      case path.startsWith(paths.DATASETS):
        setActiveTabs(['Datasets']);
        break;
      default:
        setActiveTabs(defaultTabSelection);
    }
  }, [location.pathname]);

  const standalone = getUrlParam(URL_PARAMS.standalone);
  if (standalone || uiConfig.hideNav) return <></>;

  const renderSubMenu = ({
    label,
    childs,
    url,
    index,
    key,
    isFrontendRoute,
  }: MenuObjectProps) => {
    if (url && isFrontendRoute) {
      return (
        <DropdownMenu.Item key={label} role="presentation">
          <NavLink role="button" to={url} activeClassName="is-active">
            {label}
          </NavLink>
        </DropdownMenu.Item>
      );
    }
    if (url) {
      return (
        <DropdownMenu.Item key={key}>
          <a href={url}>{label}</a>
        </DropdownMenu.Item>
      );
    }
    return (
      <SubMenu
        key={index}
        title={label}
        icon={showMenu === 'inline' ? <></> : <Icons.TriangleDown />}
      >
        {childs?.map((child: MenuObjectChildProps | string, index1: number) => {
          if (typeof child === 'string' && child === '-' && label !== 'Data') {
            return <DropdownMenu.Divider key={`$${index1}`} />;
          }
          if (typeof child !== 'string') {
            return (
              <DropdownMenu.Item key={`${child.label}`}>
                {child.isFrontendRoute ? (
                  <NavLink
                    to={child.url || ''}
                    exact
                    activeClassName="is-active"
                  >
                    {child.label}
                  </NavLink>
                ) : (
                  <a href={child.url}>{child.label}</a>
                )}
              </DropdownMenu.Item>
            );
          }
          return null;
        })}
      </SubMenu>
    );
  };

  const homeClick = () => {
    if (window.location.pathname === '/register/' || window.location.pathname === '/login/') {
      window.location.href = 'https://bi.datawake.cn/home/';
    } else {
      history.push('/home/');
      // window.location.href = '/home/';
    }
  };
  const toAdtopic = () => {
    selectItem.current = 'adtopic'
  }
  const toHome = () => {
    // window.location.assign('/dashboard/list/?filters=(id:(label:预置看板,value:pre))')
    selectItem.current = 'home'
  }
  const toNavPop = (e: React.MouseEvent<HTMLElement>, type: string) => {
    setOpenToolStatus(false)
    setOpenDataStatus(false)
    setOpenAiStatus(false)
    // e.preventDefault()
    // e.stopPropagation()
    selectItem.current = type
  }

  const toFbRule = () => {
    selectItem.current = 'fb';
    history.push('/fb/');
  };

  const toFbAuth = () => {
    selectItem.current = 'fb_auth';
    history.push('/fb/auth/');
  };

  const toFbHome = () => {
    selectItem.current = 'fb_home';
    history.push('/fb/home/');
  };

  const toFbSimpleRule = () => {
    selectItem.current = 'fb_rule';
    history.push('/fb/rule/');
  };

  if (window.location.pathname === '/privacy/') {
    return <></>;
  }
  return (
    <StyledHeader className="top" id="main-menu" role="navigation" style={window.location.pathname === '/register/' || window.location.pathname === '/login/' ? {
      backgroundColor: '#F1F5FE'
    } : {}}>
      {
        window.location.pathname === '/home/' || window.location.pathname === '/pricing/' ? null :
          <><Global styles={globalStyles(theme)} />
            <Row style={{ alignItems: 'center', height: 60, marginBottom: (isDemo && !isHostFb) ? '30px' : '0' }}>
              <Col md={16} xs={24}>
                <Tooltip
                  id="brand-tooltip"
                  placement="bottomLeft"
                  title={brand.tooltip}
                  arrowPointAtCenter
                >
                  <span tabIndex={0} role="button" onClick={homeClick} className="navbar-brand">
                    <img src={brand.icon} alt={brand.alt} />
                  </span>
                </Tooltip>
                {brand.text && (
                  <div className="navbar-brand-text">
                    <span>{brand.text}</span>
                  </div>
                )}
                {window.location.pathname === '/register/' || window.location.pathname === '/login/' ? (
                  <></>
                ) : (
                  <StyledTopNav>
                    {
                      isHostFb ? (
                        <div className='topNav'>
                          <div onClick={toFbHome} className={selectItem.current === 'fb_home' ? 'navItem navItem_active' : 'navItem'}>
                            <span>首页</span>
                          </div>
                          <div onClick={toFbRule} className={selectItem.current === 'fb' ? 'navItem navItem_active' : 'navItem'}>
                            <span>AI投手</span>
                          </div>
                          <div onClick={toFbAuth} className={selectItem.current === 'fb_auth' ? 'navItem navItem_active' : 'navItem'}>
                            <span>Facebook授权</span>
                          </div>
                          <div onClick={toFbSimpleRule} className={selectItem.current === 'fb_rule' ? 'navItem navItem_active' : 'navItem'}>
                            <span>规则引擎</span>
                          </div>
                        </div>
                      ) : (
                        <div className="topNav">
                          <div onClick={toHome} className={selectItem.current === 'home' ? 'navItem navItem_active' : 'navItem'}>
                            <a href="/superset/dashboard/4822/?native_filters_key=BUM_8ZrUp78iy3Io7QOWToqY8_HQ7pp3J5_NBwFL9K_Lo8YBXaY7hu2myLvNFLea">首页</a>
                          </div>
                          <div onClick={toAdtopic} className={selectItem.current === 'adtopic' ? 'navItem navItem_active' : 'navItem'}>
                            <a href="/adtopic">广告优化</a>
                          </div>
                          <Dropdown
                            placement="bottomLeft"
                            getPopupContainer={getPopupAiContainer}
                            visible={openAiStatus}
                            onVisibleChange={handleAiOpenChange}
                            overlay={dropdownAiRender || null}
                          >
                            <div className={selectItem.current === 'AI分析教练' || selectItem.current === 'smallApp' ? 'navItem navItem_active' : 'navItem'}>AI问答
                              <img src={Chevrondown} alt="" style={{ right: '10px' }} />
                            </div>
                          </Dropdown>
                          <Dropdown
                            placement="bottomLeft"
                            getPopupContainer={getPopupDataContainer}
                            visible={openDataStatus}
                            onVisibleChange={handleDataOpenChange}
                            overlay={dropdownDataRender || null}
                          >
                            <div className={selectItem.current === 'chart' || selectItem.current === 'data' || selectItem.current === 'customAnalysisGuide' || selectItem.current === 'topDashboard' ? 'navItem navItem_active' : 'navItem'}>经营分析
                              <img src={Chevrondown} alt="" style={{ right: '2px' }} />
                            </div>
                          </Dropdown>
                          <Dropdown
                            placement="bottomLeft"
                            getPopupContainer={getPopupToolContainer}
                            visible={openToolStatus}
                            // visible={openToolStatus}
                            onVisibleChange={handleToolOpenChange}
                            overlay={dropdownToolRender || null}
                          >
                            <div className={selectItem.current === 'fb' || selectItem.current === 'reports' || selectItem.current === 'inspection' || selectItem.current === 'monitor' ? 'navItem navItem_active' : 'navItem'}>工具
                              <img src={Chevrondown} alt="" style={{ right: '20px' }} />
                            </div>
                          </Dropdown>
                        </div>
                      )
                    }
                  </StyledTopNav>
                )}
              </Col>
              <Col md={8} xs={24}>
                {window.location.pathname === '/register/' || window.location.pathname === '/login/' ? (
                  <></>
                ) : (
                  <RightMenu
                    align={screens.md ? 'flex-end' : 'flex-start'}
                    settings={settings}
                    navbarRight={navbarRight}
                    isFrontendRoute={isFrontendRoute}
                    environmentTag={environmentTag}
                  />
                )}
              </Col>
              {(isDemo && !isHostFb) && <div className='brandTip'>您已进入DEMO环境，可体验所有基础产品功能。</div>}
            </Row></>
      }
    </StyledHeader>
  )
}

// transform the menu data to reorganize components
export default function MenuWrapper({ data, ...rest }: MenuProps) {
  const newMenuData = {
    ...data,
  };
  // Menu items that should go into settings dropdown
  const settingsMenus = {
    Data: true,
    Security: true,
    Manage: true,
  };

  // Cycle through menu.menu to build out cleanedMenu and settings
  const cleanedMenu: MenuObjectProps[] = [];
  const settings: MenuObjectProps[] = [];
  newMenuData.menu.forEach((item: any) => {
    if (!item) {
      return;
    }

    const children: (MenuObjectProps | string)[] = [];
    const newItem = {
      ...item,
    };

    // Filter childs
    if (item.childs) {
      item.childs.forEach((child: MenuObjectChildProps | string) => {
        if (typeof child === 'string') {
          children.push(child);
        } else if ((child as MenuObjectChildProps).label) {
          children.push(child);
        }
      });

      newItem.childs = children;
    }

    if (!settingsMenus.hasOwnProperty(item.name)) {
      cleanedMenu.push(newItem);
    } else {
      settings.push(newItem);
    }
  });

  newMenuData.menu = cleanedMenu;
  newMenuData.settings = settings;

  return <Menu data={newMenuData} {...rest} />;
}
