import { Platform } from 'src/pages/Pricing/type';
import { allHttpRequest } from 'src/utils/httpRequest';
import { OrderListRequest } from '../../type';

// 获取我的订单列表
export const getMyOrderList = async (filter: OrderListRequest) => {
  const res = await allHttpRequest.post('/llm_server/pay/order/list', filter);
  return res;
};

// 取消支付
export const cancelMyOrder = async (order_id: string) => {
  const res = await allHttpRequest.post(`/llm_server/pay/order/cancel/${order_id}`);
  return res;
};

// 获取过期时间
export const getMySubscript = async (platform: Platform = 'AD_TOPIC') => {
  const res = await allHttpRequest.get(`/llm_server/pay/my_subscription?platform=${platform}`);
  return res;
};
