import React, { memo } from 'react';
import classNames from 'classnames';
import AddIcon from 'src/assets/images/icons/add.svg';
import styles from './index.module.less';

interface ConfirmButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  text?: string;
  hasIcon?: boolean;
  customIcon?: React.ReactNode;
  className?: string;
  loading?: boolean;
  isDefault?: boolean;
  title?: string;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
  const { onClick, disabled, text, hasIcon, customIcon, className, loading, isDefault, title } = props;

  const handleClick = () => {
    if (disabled || loading) {
      return;
    }
    onClick?.();
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(styles.add_btn, className, {
        [styles.disabled]: disabled || loading,
        [styles.has_icon]: (hasIcon || customIcon),
        [styles.default]: isDefault,
      })}
      onClick={handleClick}
      title={title}
    >
      {hasIcon && !customIcon && <AddIcon />}
      {customIcon}
      <span>{text || '规则'}</span>
    </div>
  );
};

export default memo(ConfirmButton);
